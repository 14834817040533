<template>
  <div class="overflow-x-auto lg:overflow-x-visible min-h-[480px]">
    <section v-if="!populated" class="flex items-center justify-center py-5 grow text-slate-400 min-h-[486px]">
      <Spinner/>
    </section>
    <section v-else-if="rounds.length === 0" class="flex items-center justify-center py-5 grow text-slate-400 min-h-[486px]">
      <span class="icon-ico-info text-2xl mr-1"/>
      <span class="capitalize">Round history not found</span>
    </section>
    <section v-else class="text-left whitespace-nowrap animate-fade-in min-w-[725px] sm:min-w-[700px]">
      <ListHeader class="lg:top-16 z-10 border-b-[1px] border-b-slate-500 !bg-black/50 text-slate-100 text-xs sm:text-sm flex">
        <h3 class="py-3 pl-3 min-w-[165px]">
          Date/Time
        </h3>
        <h3 class="py-3 min-w-[73px]">
          Ticks
        </h3>
        <h3 class="py-3 w-full">
          Seed
        </h3>
      </ListHeader>
      <div class="relative z-0">
        <button
          v-for="(round, index) in rounds"
          :key="round.id"
          class="w-full text-gray-400 border-b border-slate-500 py-1 lg:py-3 items-center max-h-[200px] transition-all duration-200 relative animate-slide-down-fade-in01s flex flex-row flex-nowrap outline-none"
          :class="[
            index % 2 !== 0 ? 'bg-black/55' : 'bg-black/25',
            selectable ? 'cursor-pointer' : '',
          ]"
          @click.prevent="selectRound(round)"
        >
          <div class="py-1 pl-3 text-xs min-w-[165px]">
            {{ $dayjs(round.startTime).format('MMM DD YYYY, HH:mm:ss') }}
          </div>
          <div class="py-1 text-xs text-white min-w-[73px] tabular-nums">
            {{ round.ticks || 0 }}
          </div>
          <div class="py-1 pr-3 text-xs text-slate-50 tabular-nums">
            {{ round.seed || '--' }}
          </div>
        </button>
      </div>
    </section>
  </div>
</template>

<script setup>
import { useTradingStore } from '@/store/trading';

const props = defineProps({
  assetCode: {
    type: Array,
    required: true,
  },
  maxCount: {
    type: Number,
    default: 10,
  },
  selectable: {
    type: Boolean,
    default: false,
  },
});

const tradingStore = useTradingStore();

const {
  fetchRoundHistory,
} = tradingStore;

const {
  $dayjs,
} = useNuxtApp();

const emit = defineEmits(['select',]);

const populated = ref(false);
const rounds = ref([]);

onMounted(async() => {
  await init();

  if (props.selectable) {
    emit('select', rounds.value[0]);
  }
});

async function init() {
  rounds.value = await fetchRoundHistory(props.assetCode, props.maxCount);
  populated.value = true;
}

function selectRound(round) {
  if (!props.selectable) {
    return;
  }

  emit('select', round);
}
</script>
